import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import useWallet from "hooks/useWallet";
import { Entry } from "interfaces/entry";
import { createRef, useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useAppSelector } from "state/hooks";
import { getGroupCurrencySymbol, getGroupSecondaryColorOrDefault, getGroupState } from "state/slices/group-slice";
import { formatAsCurrency, formattedCurrencyToNumber, } from "utils/formatter-utils/currency-formatter";
import styles from "components/pool-details/bet-modal/bet-modal-content/styles.module.css";
import ChangeHighlight from "react-change-highlight";
import OpenSansText from "components/custom-texts/open-sans-text";
import { calculateOdds } from "utils/oddsCalculator";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import confirmBet from "../confirm-bet";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { Bet } from "interfaces/bet";
import { toast } from "react-toastify";
import {useBetsData} from "hooks/useBetsData";

interface Props {
  entry: Entry;
  pool: PoolDetailsResponse;
  onSuccessfulBetPlaced: (bet: Bet) => void;
  contestEntryId?: string;
}

const BetForm = ({ entry, pool, onSuccessfulBetPlaced, contestEntryId }: Props) => {
  const { formattedBalance, balance } = useWallet({ contestEntryId });
  const [betAmount, setBetAmount] = useState("");
  const { bets } = useBetsData(contestEntryId);
  const [loading, setLoading] = useState(false);
  const group = useAppSelector(getGroupState);
  const [errorMessage, setErrorMessage] = useState<string>();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      (inputRef.current as any).focus();
    }
  }, [inputRef, entry]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;
  const currencySymbol = useAppSelector(getGroupCurrencySymbol);
  const contestantIds = entry.contestant_id
    .split(";")
    .filter((id) => id.length > 0);
  const selectedContestants = contestantIds.map((id) => {
    const contestant = pool.contestants.find((c) => c.id === id);
    return contestant;
  });

  const numberOfSelections = selectedContestants.length;
  const poolTotalKey = ["total_win", "total_exacta", "total_trifecta"][
  numberOfSelections - 1
    ];
  const poolTotal = (pool as any)[poolTotalKey];

  const odds = calculateOdds({
    poolTotal,
    entryTotal: entry.bet_total || entry.bets_sum || 0,
    betAmount: formattedCurrencyToNumber(betAmount) || 0,
    fee: pool.fee,
  });

  const projectedPayout =
    formattedCurrencyToNumber(betAmount || "0") * (odds || 0);

  const formattedProjectedPayout = formatAsCurrency(projectedPayout);
  const winType = poolTotalKey === "total_win";
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "-webkit-fill-available",
        background: "black",
        color: "white",
        padding: 10,
        fontSize: 14,
      }}
    >
      {/* top panel */}
      <div style={{ width: "100%", display: "flex", padding: 2 }}>
        {/* left panel */}
        <div style={{ flex: 0.5 }}>
          <BlockyBoldText>
            Available Balance:{" "}
            <span style={{ color: secondaryColor }}>{formattedBalance}</span>
          </BlockyBoldText>
          {/* Clarifying text */}
          {balance !== undefined &&
              bets !== undefined && contestEntryId !== undefined &&
              balance - formattedCurrencyToNumber(betAmount) <
              resolveMinBetAmountContestEntry(bets, contestEntryId, balance, numberOfSelections) && (
                  <div style={{ marginTop: 5, color: "red", fontSize: 12 }}>
                    You must keep at least{" "}
                    {currencySymbol}
                    {resolveMinBetAmountContestEntry(bets, contestEntryId, balance, numberOfSelections)} to complete your
                    entry with other picks.
                  </div>
              )}
        </div>
        {/* right panel */}
        <div style={{ flex: 0.5 }}>
          <BlockyBoldText>
            Selected:{" "}
            {!winType &&
              selectedContestants.map((selection, index) => {
                return (
                  <span key={index} style={{ color: secondaryColor }}>
                    {selection?.name} ({index + 1});{" "}
                  </span>
                );
              })}
            {winType && (
              <span style={{ color: secondaryColor }}>
                {entry.contestant_name}
              </span>
            )}
          </BlockyBoldText>
        </div>
      </div>

      {/* middle panel */}
      <div style={{ display: "flex", padding: 3 }}>
        {/* left */}
        <div style={{ flex: 0.5 }}>
          <NumberFormat
            id="input-BetModalContent-betAmountInput"
            getInputRef={inputRef}
            onValueChange={(value) => {
              if (!value.floatValue) {
                setBetAmount("$0.00");
              }

              if (balance !== undefined && formattedCurrencyToNumber(value.formattedValue) <= balance!) {
                setBetAmount(value.formattedValue);
              }
            }}
            prefix={currencySymbol}
            decimalScale={2}
            thousandSeparator
            inputMode="decimal"
            fixedDecimalScale
            allowNegative={false}
            autoFocus
            disabled={loading}
            isAllowed={(value) => {
              if (balance === undefined) {
                return false;
              }
              if (value && value.floatValue) {
                return value && value?.floatValue <= balance!;
              }
              return true;
            }}
            placeholder="Enter Amount"
            style={{
              borderRadius: 5,
              height: 40,
              color: "black",
              width: "95%",
            }}
          />

          <div style={{ display: "flex" }}>
            <BlockyBoldText style={{ marginTop: 10, display: "flex" }}>
              Odds: &nbsp;
              <ChangeHighlight
                hideAfter={1000}
                highlightClassName={styles.highlight}
              >
                <div
                  ref={createRef()}
                  style={{ color: secondaryColor }}
                >{`${odds.toFixed(2)}X`}</div>
              </ChangeHighlight>
            </BlockyBoldText>
          </div>
        </div>
        {/* right */}
        <div style={{ width: "100%", flex: 0.5 }}>
          <Button
            style={{
              width: "100%",
              height: 41,
              backgroundColor: secondaryColor,
              color: "black",
            }}
            disabled={
                loading ||
                !betAmount ||
                betAmount === "$0.00" ||
                (balance !== undefined &&
                    bets && contestEntryId !== undefined &&
                balance - formattedCurrencyToNumber(betAmount) < resolveMinBetAmountContestEntry(bets, contestEntryId, balance, numberOfSelections))
            }
            onClick={() => {
              confirmBet({
                isIntegratedApp,
                betAmount,
                poolEntryId: entry.id || entry.pool_entry_id,
                pool_id: pool.id,
                setLoading,
                groupId: group.id,
                setErrorMessage,
                onSuccessfulBetPlaced: (bet: Bet) => onSuccessfulBetPlaced(bet),
                currency: group.currency_code,
                contest_entry_id: contestEntryId,
              });
            }}
          >
            {loading && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginRight: 10 }}
              />
            )}
            Confirm
          </Button>

          <div style={{ marginTop: 7 }}>
            <div style={{ display: "flex" }}>
              <BlockyBoldText>Expected Return: &nbsp;</BlockyBoldText>
              <BlockyBoldText className={styles.text}>
                <ChangeHighlight
                  hideAfter={1000}
                  highlightClassName={styles.highlight}
                >
                  <span ref={createRef()} style={{ color: secondaryColor }}>
                    {formattedProjectedPayout}
                  </span>
                </ChangeHighlight>
              </BlockyBoldText>
            </div>
          </div>
        </div>
      </div>
      <OpenSansText
        className={styles.disclaimerText}
        style={{ fontSize: 10, textAlign: "center", color: "gray" }}
      >
        By Clicking 'Confirm' the amount indicated will be credited from your
        balance. Expected returns are subject to change based on subsequent
        activity in the pool.
      </OpenSansText>
    </div>
  );
};

const resolveMinBetAmountContestEntry = (bets: Bet[], contestEntryId: string, balance: number, numberOfSelections: number) => {
  const contestBets = bets.filter(b => b.contest_entry_id === contestEntryId);
  // Create a Set of unique contestants by splitting names by `;`
  const uniqueContestantBets = new Set();
  contestBets.forEach(bet => {
    if (bet.contestant_name) {
      bet.contestant_name.split(';').forEach(name => uniqueContestantBets.add(name.trim()));
    }
  });
  const contestantVal = uniqueContestantBets.size + numberOfSelections
  if (contestantVal <= 1) {
    return balance <= 200 ? 0: 200
  }
  if (contestantVal === 2) {
    return balance <= 100 ? 0: 100
  }
  return 0;
};

export default BetForm;
