import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { LongButton } from "components/contests/contest-display-items/shared/buttons";
import {ErrorResponseBody} from "../../../interfaces/error-response-body";
import {ErrorCodes} from "../../../interfaces/error-codes";

interface NavigateToInovioHostedPaymentPageButtonProps {
  title: string;
  provideInovioHostedPaymentPageURL: () => Promise<string>;
  disabled?: boolean;
  setLoading?: (loading: boolean) => void;
  onRedirecting?: () => void;
}

const NavigateToInovioHostedPaymentPageButton = ({
                                                   title,
                                                   provideInovioHostedPaymentPageURL,
                                                   disabled = false,
                                                   setLoading = () => {
                                                   },
                                                   onRedirecting = () => {
                                                   },
                                                 }: NavigateToInovioHostedPaymentPageButtonProps) => {

  const [navigating, setNavigating] = useState(false);

  const navigateToPaymentPage = async () => {
    setNavigating(true);
    setLoading(true);

    try {
      let paymentPageURL;

      try {
        paymentPageURL = await provideInovioHostedPaymentPageURL();
      } catch (error: any) {
        const errorResponse: ErrorResponseBody | undefined = error.response?.data;
        if (errorResponse) {
          const error_code = errorResponse.error_code;
          switch (error_code) {
            case ErrorCodes.DepositLimitationExceeded:
              toast.error(errorResponse.friendly_error_message);
              return;
            }
        }
        toast.error("Failed to prepare payment page. Please contact support.");
        console.error("failed to obtain paymentPageURL", error);
        return;
      }

      try {
        window.location.href = paymentPageURL;
        onRedirecting();
      } catch (e) {
        toast.error("Failed to navigate to the payment page. Please contact support.");
        console.error("failed to redirect to " + paymentPageURL, e);
      }
    } catch (e) {
      setLoading(false);
      setNavigating(false);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <LongButton
        disabled={navigating || disabled}
        onClick={navigateToPaymentPage}
        text={title}
      />

      {navigating && (
        <BlockyBoldText
          className="text-center m-2"
          style={{ color: "white", fontSize: "1.2rem" }}
        >
          Navigating you to the payment page...
        </BlockyBoldText>
      )}
    </div>
  );
};

export default NavigateToInovioHostedPaymentPageButton;
