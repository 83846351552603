import MyContestBanner from "./my-contest-banner.tsx";
import React, { useState } from "react";
import styles from "components/contests/contest-details/styles.module.css";
import {
  ContestItem,
  EnterContestButton,
} from "components/contests/contest-display-items";
import { Contest } from "interfaces/leaderboard/contest.ts";
import {
  CONTEST_ENTRY_STATUS,
  CONTEST_STATE,
  sparketBlack,
  sparketGold,
} from "utils/constants.ts";
import ViewLeaderboardButton from "./view-leaderboard-button.tsx";
import { useAppDispatch, useAppSelector } from "state/hooks.ts";
import { getStatusFromEntryId } from "state/slices/contests-slice.ts";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import { showSignIn } from "state/slices/login-modals-slice.ts";
import { useAuth } from "hooks/auth.tsx";
import { getGroupState, requestToJoinGroup } from "state/slices/group-slice.ts";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";
import JoinGroupModal from "home/join-group-button/join-group-modal";
import { Bet } from "interfaces/bet.ts";

interface Props {
  contest: Contest;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  contestEntryId?: string;
  bets?: Bet[];
}

const MyContestHeader = ({
  contest,
  setShowModal,
  contestEntryId,
  bets,
}: Props) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const contestStatus = useAppSelector((state) =>
    getStatusFromEntryId(state, contestEntryId || "")
  );

  const group = useAppSelector(getGroupState);
  const userHasJoinedGroup =
    group.status === UserGroupMembershipStatus.APPROVED;
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);
  const hasNotJoinedContest = group.isContestGroup && !contestEntryId;

  const onClick = () => {
    if (!auth.signedIn) {
      dispatch(showSignIn());
      return;
    }

    if (group.name && !userHasJoinedGroup) {
      setShowJoinGroupModal(true);
      return;
    }

    setShowModal(true);
  };

  const showLeaderboardButton =
    !!contestEntryId || contest.state !== CONTEST_STATE.OPEN;

  const resolveContestEntryStatusInfoMessage = (
    bets: Bet[],
    contestEntryId: string
  ) => {
    const contestBets = bets.filter(
      (b) => b.contest_entry_id === contestEntryId
    );
    const uniqueContestantBets = new Set();
    contestBets.forEach(bet => {
      if (bet.contestant_name) {
        bet.contestant_name.split(';').forEach(name => uniqueContestantBets.add(name.trim()));
      }
    });
    if (uniqueContestantBets.size === 0) {
      return `Make at least 3 picks with different contestants to enter!`;
    }
    if (uniqueContestantBets.size === 1) {
      return `Make at least 2 picks with different contestants to enter!`;
    }
    return `Make at least 1 more pick with a different contestant to enter!`;
  };

  return (
    <>
      <div style={{ backgroundColor: sparketBlack }}>
        <MyContestBanner contestEntryId={contestEntryId} />
        <div className={styles.wrapper}>
          <ContestItem
            contest={contest}
            contestEntryId={contestEntryId}
            showStatus
          />
          {showLeaderboardButton ? (
            <ViewLeaderboardButton contestId={contest.id} />
          ) : (
            <EnterContestButton onClick={onClick} />
          )}

          <div>
            {contestEntryId &&
              bets &&
              contestStatus === CONTEST_ENTRY_STATUS.PENDING && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: sparketGold,
                    fontSize: 12,
                    marginBottom: 10
                  }}
                >
                  <BlockyBoldText>
                    {resolveContestEntryStatusInfoMessage(bets, contestEntryId)}
                  </BlockyBoldText>
                </div>
              )}
          </div>
        </div>
      </div>
      <JoinGroupModal
        acceptAndJoin={() => {
          dispatch(requestToJoinGroup(group.id));
          setShowJoinGroupModal(false);
          if (hasNotJoinedContest) {
            setShowModal(true);
          }
        }}
        show={showJoinGroupModal}
        close={() => setShowJoinGroupModal(false)}
      />
    </>
  );
};

export default MyContestHeader;
