import { Contest } from "interfaces/leaderboard/contest.ts";
import ContestItem from "./contest-item.tsx";
import { useNavigate } from "react-router-dom";
import styles from "./contest-item-home.module.scss"

interface ContestItemProps {
  contest: Contest;
}

const ContestItemHome = ({ contest }: ContestItemProps) => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contest/${contest.id}`, { state: { contest: contest } })
  }

  return (
    <>
      <div
        className={styles.wrapper}
        onClick={handleClick}
      >
          <ContestItem contest={contest}/>
      </div>
    </>
  )
};

export default ContestItemHome;
