import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import useWallet from "hooks/useWallet";

interface Props {
  contestEntryId: string;
}

export const BalanceHeader = ({ contestEntryId }: Props) => {
  const { formattedBalance } = useWallet({ contestEntryId });

  return (
    <div style={{ margin: 8, display: "flex", alignItems: "flex-end" }}>
      <BlockyHeavyText
        style={{
          fontSize: 12,
          marginRight: 5,
          color: "var(--sparket-off-white)",
        }}
      >
        Balance:&nbsp;
      </BlockyHeavyText>
      <BlockyHeavyText
        style={{
          fontWeight: "bold",
          fontSize: 14,
          marginRight: 10,
          color: "white",
        }}
      >
        {formattedBalance}
      </BlockyHeavyText>
    </div>
  );
};

export default BalanceHeader;
