import { BackButton } from "components/contests/contest-display-items/shared/buttons";
import BalanceHeader from "../header/balance-header";
import { ReactComponent as MyPicksIcon } from "icons/MYPICKS.svg";
import { secondaryColor, sparketBlack } from "utils/constants";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "components/contests/contest-display-items/shared/styles.module.css";
import { toggle as toggleMyPickState } from "state/slices/my-picks-popout-slice.ts";
import { useAppDispatch } from "state/hooks.ts";
import { local as localStorage } from "utils/local-storage";
import { Button } from "react-bootstrap";

interface Props {
  contestEntryId?: string;
}

const MyContestBanner = ({ contestEntryId }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 37
      }}
    >
      <div style={{ position: "absolute", left: 0 }}>
        <BackButton
          size="sm"
          backUrl={"/" + localStorage.getItem("prevPath") || "/"}
        />
      </div>

      {!!contestEntryId && (
        <>
          <BalanceHeader contestEntryId={contestEntryId} />

          <Button
            size="sm"
            onClick={() => {
              dispatch(toggleMyPickState());
            }}
            className={styles.prizeContainer}
            style={{
              position: "absolute",
              right: 20,
              padding: "4px 8px",
              backgroundColor: secondaryColor,
              borderColor: secondaryColor,
              color: sparketBlack,
            }}
          >
            <BlockyHeavyText style={{ fontSize: 11 }}>
              <MyPicksIcon style={{ marginRight: 4 }} height={15} width={15} />
              My Picks
            </BlockyHeavyText>
          </Button>
        </>
      )}
    </div>
  );
};

export default MyContestBanner;
