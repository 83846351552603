import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import Header from "components/header";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { secondaryColor, sparketBlack, SUCCEEDED } from "utils/constants";
import { getRequest, postRequest } from "utils/httpClient";
import Button from "react-bootstrap/Button";
import Headroom from "react-headroom";
import { Alert, Form, Spinner } from "react-bootstrap";
import styles from "./styles.module.css";
import { useAuth } from "hooks/auth";
import LoadingSpinner from "components/loading-spinner.tsx";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { Mixpanel } from "hooks/mixPanel";
import { DatePicker } from "react-rainbow-components";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { fetchUser, getUserState } from "state/slices/user-slice";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";
import BlockyLightText from "components/custom-texts/blocky-light-text";
import { toast } from "react-toastify";
import VerifyProfileResponse from "interfaces/verify-profile-response";
import { XLg } from "react-bootstrap-icons";
import { getUsersPersonalInformationPath } from "utils/backend-path-builders";
import { formatDateToYYYYMMDD } from "utils/formatter-utils/date-formatter";
import { calculateAge } from "utils/time-utils";

export const MIN_AGE_ALLOWED = 18;

const IdentityVerificationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAuth();

  const [submitting, setSubmitting] = useState(false);
  const [loadingPersonalInfo, setLoadingPersonalInfo] = useState(false);

  const [state, setState] = useState<string>();
  const [city, setCity] = useState<string>();
  const [streetAddress, setStreetAddress] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<Date>();

  const [verificationAttemptResult, setVerificationAttemptResult] = useState<
    IDENTITY_VERIFICATION_STATUS.APPROVED |
    IDENTITY_VERIFICATION_STATUS.REJECTED |
    IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE |
    IDENTITY_VERIFICATION_STATUS.DUPLICATED |
    undefined
  >();

  const user = useAppSelector(getUserState);
  const userLoaded = user.fetchUserState === SUCCEEDED;
  const userVerified = userLoaded ? user.validation_status === IDENTITY_VERIFICATION_STATUS.APPROVED : undefined;

  useEffect(() => {
    setLoadingPersonalInfo(true);
    getRequest(getUsersPersonalInformationPath())
      .then((data) => {
        const {
          first_name,
          last_name,
          postal_code,
          date_of_birth,
          street,
          city,
          state
        } = data;
        if (first_name) {
          setStreetAddress(street);
          setCity(city);
          setState(state);
          setFirstName(first_name);
          setLastName(last_name);
          setPostalCode(postal_code);
          setDateOfBirth(new Date(date_of_birth));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoadingPersonalInfo(false));
  }, []);

  const dateOfBirthError = dateOfBirth && calculateAge(dateOfBirth) < MIN_AGE_ALLOWED;

  if (auth.pending) {
    return <LoadingSpinner />;
  } else if (!auth.signedIn) {
    return <Navigate to="signin" />;
  }

  const getVerificationResultMessage = () => {
    switch (verificationAttemptResult) {
      case IDENTITY_VERIFICATION_STATUS.APPROVED:
        return <BlockyBoldText className={"text-center"}>
          Your ID was verified successfully!
        </BlockyBoldText>;
      case IDENTITY_VERIFICATION_STATUS.REJECTED:
        return <BlockyBoldText className={"text-center"}>
          Your ID verification failed.
          Please check your ID and update your information, or contact {" "}
          <a href="mailto:support@betsparket.com">support@betsparket.com</a>.
        </BlockyBoldText>;
      case IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE:
        return <BlockyBoldText className={"text-center"}>
          Your verification was unsuccessful because you must be at least 18 years old to proceed.
          Please try again when you meet the age requirement.
        </BlockyBoldText>;
      case IDENTITY_VERIFICATION_STATUS.DUPLICATED:
        return <BlockyBoldText className={"text-center"}>
          This ID is already verified under another account. Please contact{" "}
          <a href="mailto:support@betsparket.com">support@betsparket.com</a>.
        </BlockyBoldText>;
      default:
        return <></>;
    }
  };

  const calculateMaxAllowedDate = () => new Date(new Date().setFullYear(new Date().getFullYear() - MIN_AGE_ALLOWED));

  const formIsDisabled = () => !userLoaded || userVerified || loadingPersonalInfo;

  const handleSubmitButtonClick = () => {
    if (!dateOfBirth) {
      toast.error("Please enter your date of birth.");
      return;
    }

    Mixpanel.track("Clicked Submit Profile", { isSignedIn: auth.signedIn });

    setSubmitting(true);
    postRequest(`/v2/users/user/${user.id}/verification/idscan/screening-check`, {
      body: {
        firstName: firstName,
        lastName: lastName,
        address: streetAddress,
        city: city,
        state: state,
        zip: postalCode,
        dateOfBirths: formatDateToYYYYMMDD(dateOfBirth),
      }
    })
      .then((response: VerifyProfileResponse[]) => {
        if (response.length === 0 || response[0].profiles.length !== 1) {
          toast.error("Something went wrong. Please try again.");
          setVerificationAttemptResult(undefined);
        } else {
          const profile = response[0].profiles[0];
          if (profile.sparketResult !== IDENTITY_VERIFICATION_STATUS.NOT_STARTED) {
            setVerificationAttemptResult(profile.sparketResult);

            if (profile.sparketResult === IDENTITY_VERIFICATION_STATUS.APPROVED) {
              setTimeout(() => navigate("/"), 3000);
            }
          } else {
            toast.error("Something went wrong. Please try again.");
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.technical_error) {
          toast.error(error.response.data.technical_error);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      })
      .finally(() => {
        dispatch(fetchUser());
        setSubmitting(false);
      });
  };

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <div
        style={{
          backgroundColor: sparketBlack,
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 10,
        }}
      >
        {userLoaded &&
          <BlockyBoldText className={"text-center"} style={{ color: secondaryColor, fontSize: 30 }}>
            {userVerified ? "Profile info" : "Verify your identity"}
          </BlockyBoldText>
        }
      </div>
      <div>
        <div className={styles.profileContainer} style={{ position: "relative", padding: "10px" }}>
          {/* Close Button Positioned Absolutely */}
          <XLg
            style={{
              width: "20px",
              height: "20px",
              position: "absolute",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/profile")}
          />

          {/* Centered Text */}
          {userLoaded && !userVerified && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
                marginRight: "30px",
                marginLeft: "30px"
              }}
            >
              <BlockyBoldText className="text-center p-1">
                Please enter your information exactly as it appears on your government issued ID
              </BlockyBoldText>
            </div>
          )}


          <Form.Label className={styles.labelStyles}>First Name (without middle name)*</Form.Label>
          <Form.Control
            className={styles.inputStyles}
            disabled={formIsDisabled()}
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            isInvalid={firstName === ""}
          />
          <Form.Label className={styles.labelStyles}>Last Name *</Form.Label>
          <Form.Control
            className={styles.inputStyles}
            disabled={formIsDisabled()}
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            isInvalid={lastName === ""}
          />

          <Form.Label className={styles.labelStyles}>Street Address *</Form.Label>
          <Form.Control
            disabled={formIsDisabled()}
            className={styles.inputStyles}
            placeholder="Street Address"
            onChange={(e) => setStreetAddress(e.target.value)}
            value={streetAddress}
            isInvalid={streetAddress === ""}
          />

          <Form.Label className={styles.labelStyles}>City *</Form.Label>
          <Form.Control
            disabled={formIsDisabled()}
            className={styles.inputStyles}
            placeholder="City"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            isInvalid={city === ""}
          />

          <Form.Label className={styles.labelStyles}>State (two letters) *</Form.Label>
          <Form.Control
            disabled={formIsDisabled()}
            className={styles.inputStyles}
            placeholder="State"
            onChange={(e) => {
              const stateValue = e.target.value;
              if (stateValue.length <= 2) {
                setState(stateValue);
              }
            }}
            value={state}
            isInvalid={state === ""}
          />

          <Form.Label className={styles.labelStyles}>Postal Code *</Form.Label>
          <Form.Control
            disabled={formIsDisabled()}
            className={styles.inputStyles}
            placeholder="Postal Code"
            onChange={(e) => setPostalCode(e.target.value)}
            value={postalCode}
            isInvalid={postalCode === ""}
          />

          <Form.Label className={styles.labelStyles}>
            Date of Birth *
          </Form.Label>
          <div
            className={`${styles.labelStyles} `}
            style={{ marginBottom: 10 }}
          >
            <DatePicker
              disabled={formIsDisabled()}
              error={dateOfBirthError ? `must be ${MIN_AGE_ALLOWED} or older` : ""}
              maxDate={calculateMaxAllowedDate()}
              value={dateOfBirth}
              onChange={setDateOfBirth}
              formatStyle={"medium"}
              locale="en-US"
            />
          </div>

          <Alert show={verificationAttemptResult !== undefined}
                 variant={verificationAttemptResult === IDENTITY_VERIFICATION_STATUS.APPROVED ? "success" : "danger"}
                 className={"mt-4"}
                 style={{ width: "90%", textAlign: "center" }}
          >
            {getVerificationResultMessage()}
          </Alert>

          {userLoaded && !userVerified && <div className={"d-flex flex-column align-items-center"}>
            <BlockyLightText className={"mb-2"}>Your personal information will be securely encrypted.</BlockyLightText>
            <Button
              disabled={
                dateOfBirthError ||
                submitting ||
                !(
                  firstName &&
                  lastName &&
                  city &&
                  streetAddress &&
                  postalCode &&
                  state
                )
              }
              style={{
                backgroundColor: secondaryColor,
                borderRadius: "unset",
                border: `1px solid ${secondaryColor}`,
                color: sparketBlack,
                width: "90%",
                height: 45,
              }}
              onClick={() => handleSubmitButtonClick()}
            >
              {submitting ? (
                <Spinner animation="border" />
              ) : (
                <BlockyBoldText>Submit</BlockyBoldText>
              )}
            </Button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default IdentityVerificationPage;
