import { secondaryColor, sparketBlack } from "utils/constants.ts";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  backUrl: string;
  size?: "sm" | "lg";
}

const BackButton = ({ backUrl, size = "lg" }: Props) => {
  const navigate = useNavigate();

  const handleSelect = () => {
    navigate(backUrl);
  };

  return (
    <Button
      style={{
        backgroundColor: secondaryColor,
        borderColor: secondaryColor,
        color: sparketBlack,
        borderRadius: 5,
        marginLeft: 20,
        padding: size === "sm" ? "4px 8px" : "",
      }}
      onClick={handleSelect}
    >
      <BlockyHeavyText style={{ fontSize: size === "sm" ? 11 : 14 }}>
        &lt; Back
      </BlockyHeavyText>
    </Button>
  );
};

export default BackButton;
