import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { BET_STATUS, POOL_STATUS, secondaryColor, sparketGreen } from "utils/constants";
import styles from "./styles.module.css";

interface StatusBadgeProps {
  status: number;
  customStyles?: object;
  is_bet?: boolean;
  size?: string;
}

const getColorAndStatusString = (status: number, is_bet: boolean, secondaryColor: string) => {
  if (BET_STATUS.SOLD && is_bet) {
    return { backgroundColor: sparketGreen, status: "bet sold" };
  }
  switch (status) {
    case POOL_STATUS.PENDING:
      return { backgroundColor: "lightblue", status: "pending" };
    case POOL_STATUS.OPEN:
      return { backgroundColor: sparketGreen, status: "open" };
    case POOL_STATUS.LIVE:
      return { backgroundColor: secondaryColor, status: "live" };
    case POOL_STATUS.SETTLED:
      return { backgroundColor: "gray", status: "settled" };
    case POOL_STATUS.CANCELLED:
      return { backgroundColor: "gray", status: "cancelled" };
    default:
      return { backgroundColor: "gray", status: "settled" };
  }
};

const StatusBadge = ({ status, customStyles, is_bet = false, size = 'md' }: StatusBadgeProps) => {
  const displayValues = getColorAndStatusString(status, is_bet, secondaryColor);
  let className = styles.container;

  if (size === 'sm') {
    className = styles.containerSm
  }
  return (
    <div
      className={className}
      style={{
        backgroundColor: displayValues.backgroundColor,
        ...customStyles,
      }}
    >
      <BlockyHeavyText>{displayValues.status}</BlockyHeavyText>
    </div>
  );
};

export default StatusBadge;
