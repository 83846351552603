import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import styles from "./styles.module.css";
import { POOL_STATUS, secondaryColor } from "utils/constants.ts";
import {
  PrizeDisplay,
  EntryFeeDisplay,
  MaxEntriesDisplay,
} from "./shared/contest-displays";
import TimeDisplay from "./shared/time-display.tsx";
import StatusDisplay from "./entry-status";
import CountdownClock from "components/pool-item/countdown-clock.tsx";
import StatusBadge from "components/status-badge/pool-status-badge.tsx";
import ContestImage from "./shared/contest-displays/contest-image/contest-image.tsx";

interface Props {
  contest: any;
  contestEntryId?: string;
  showStatus?: boolean;
}
const ContestItem = ({ contest, contestEntryId, showStatus }: Props) => {
  const { max_entries, total_entries, entry_fee, name, headline } = contest;

  return (
    <div
      style={{
        borderRadius: 10,
        display: "flex",
        boxShadow: '0px 0px 8px #f2f2f5',
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <ContestImage url={contest.settings.logo_url} />
      <div
        style={{ margin: "10px 10px 10px 10px" }}
      >
        {/* title/headline */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* left panel empty */}
          {showStatus ? (
            <StatusBadge
              size="sm"
              customStyles={{ marginTop: 5 }}
              status={
                POOL_STATUS[
                  contest.state.toUpperCase() as keyof typeof POOL_STATUS
                ]
              }
            />
          ) : (
            <div style={{ width: 35 }} />
          )}

          {/* Middle panel is title/headline */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <BlockyHeavyText style={{ fontSize: 16, color: secondaryColor }}>
              {name}
            </BlockyHeavyText>
            <BlockyHeavyText
              style={{
                fontSize: 11,
                color: "var(--sparket-off-white)",
              }}
            >
              {headline}
            </BlockyHeavyText>
          </div>

          {/* right panel */}
          <PrizeDisplay contest={contest} />
        </div>

        {/* middle row */}
        <div className={styles.contestDetailsWrapper}>
          <div className={styles.bottomColumn}>
            <TimeDisplay endAt={contest.end_at} />
            <div style={{ fontSize: 12 }}>
              <CountdownClock close={new Date(contest.end_at)} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: 100,
            }}
          >
            <MaxEntriesDisplay
              maxEntries={max_entries}
              totalEntries={total_entries}
            />

            <EntryFeeDisplay entryFee={entry_fee} />
          </div>
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "flex-end",
              width: 130,
            }}
          >
            {contestEntryId && (
              <StatusDisplay contestEntryId={contestEntryId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestItem;
