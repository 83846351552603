import { Alert, Button, Form, Modal } from "react-bootstrap";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import OpenSansText from "components/custom-texts/open-sans-text";
import { openPrivacyPolicy, } from "state/slices/legal-modal-slice";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useNavigate } from "react-router-dom";
import { getVerificationStatus } from "state/slices/user-slice";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";
import { EDIT_PROFILE_PATH, secondaryColor, textColor } from "utils/constants";
import { useEffect, useState } from "react";
import { useTrackEvent } from "hooks/useTrackEvent";

interface Props {
  show: boolean;
  close: () => void;
  acceptAndJoin: () => void;
}

const JoinGroupModal = ({ show, close, acceptAndJoin }: Props) => {
  const group = useAppSelector(getGroupState);
  const primaryColor = group.settings.primary_color;
  const dispatch = useAppDispatch();
  const [minAgeChecked, setMinAgeChecked] = useState(false);
  const identityVerified = useAppSelector(getVerificationStatus) === IDENTITY_VERIFICATION_STATUS.APPROVED;
  const showIdentityVerificationBanner = group.settings?.requires_identity_verification && !identityVerified;
  const navigate = useNavigate();

  useEffect(() => {
    setMinAgeChecked(false);
  }, [show]);

  const trackEvent = useTrackEvent(group.id, "lead");

  const onAccept = () => {
    acceptAndJoin();
    if (showIdentityVerificationBanner) {
      navigate(EDIT_PROFILE_PATH);
    }

    trackEvent();
  };

  return (
    <Modal show={show} dialogClassName="modal-90w" onHide={close}>
      <Modal.Title
        style={{
          justifyContent: "space-between",
          backgroundColor: primaryColor,
          padding: "15px 20px",
          display: "flex",
          alignItems: "center",
        }}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <BlockyHeavyText style={{ color: textColor, fontSize: 20 }}>
            {group.name}
          </BlockyHeavyText>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            border: "none",
            padding: "5px 10px",
          }}
          onClick={close}
        >
          <BlockyHeavyText customStyles={{ color: textColor }}>X</BlockyHeavyText>
        </Button>
      </Modal.Title>
      <Modal.Body
        className={sharedModalStyles.modalBody}
        style={{ borderColor: primaryColor, padding: "20px" }} // Improved padding for the body
      >
        <p style={{ fontSize: 14 }}>
          <i>You need to join {group.name} to make a pick</i>
        </p>
        <ul
          style={{
            fontSize: 12,
            height: "auto",
            marginTop: 15,
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <li>
            <OpenSansText>
              Your profile information and pick data may be shared with{" "}
              {group.settings.group_owner} per our{" "}
              <a onClick={() => dispatch(openPrivacyPolicy())} href="#">
                Privacy Policy
              </a>
            </OpenSansText>
          </li>
        </ul>
        <Form.Check
          type="checkbox"
          id="min-age-checkmark"
          label={`I am ${group.min_age} years of age or older`}
          onChange={(event) => {
            setMinAgeChecked(event.target.checked);
          }}
          style={{ marginBottom: 20 }}
        />
        <div style={{ display: "flex", gap: "10px", marginBottom: 20 }}>
          <Button
            style={{
              backgroundColor: "white",
              borderColor: secondaryColor,
              color: "black",
              padding: "10px 20px",
            }}
            onClick={close}
          >
            <BlockyMediumText>Decline</BlockyMediumText>
          </Button>
          <Button
            style={{
              backgroundColor: secondaryColor,
              border: "none",
              color: "black",
              padding: "10px 20px",
            }}
            onClick={onAccept}
            disabled={!minAgeChecked}
          >
            <BlockyHeavyText>Accept</BlockyHeavyText>
          </Button>
        </div>
        {showIdentityVerificationBanner && (
          <Alert variant="warning">
            You must verify your identity to join this group. Clicking 'Accept' will
            kick off the verification process.
          </Alert>
        )}
      </Modal.Body>
    </Modal>

  );
};

export default JoinGroupModal;
