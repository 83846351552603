import { UICustomizations } from "interfaces/ui-customizations";
import { SectionRemoval } from "components/help/content/filter-content.ts";

export interface PageContent {
  title: string;
  body: string[];
  hide?: (groupCustomization: UICustomizations) => boolean;
}

export const PAGE_FUNCTIONALITY_CONTENT: PageContent[] = [
  {
    title: "Home Page",
    body: [
      "Access the home page anytime by selecting ‘Home’ from the toolbar on the left",
      "The home page shows Open pools available to take picks. It shows the name and the close time of the pool.",
      "Clicking on the arrow icon ’>’ or in the rectangle of a pool brings you to a Pool Page",
      "You can access the menu items on the left panel of the home page, and your profile page on the top right. On a mobile view, you may have to click the menu icon (3 horizontal lines) at the top left to expand the menu",
      "The Terms of Service, Privacy Policy, and Do Not Sell My Personal Information (CCPA) form are available on the bottom left of the menu",
      "If you are not logged in, a banner to Sign in Or Sign Up will appear across the screen",
      "If you are logged in but have not joined the group, a Join Group button will appear near the top right of the home page to bring you to the ‘Join Group’ Page",
    ],
    hide: groupCustomization => groupCustomization.contest_group
  },
  {
    title: "Contest Home Page",
    body: [
      "Enter Contests and use your skill to climb the leaderboard to win prizes!",
      "On the home page, you will see a list of contests available to join",
      "You will see the name of the contest, a brief description, a link to Contest ‘Terms’, an indication of when the contest closes, the number of entries that have been received, and the entry cost",
      "If you click on a contest, you will be brought to the View Contest Page",
      "If you click on ‘My Contests’, you will see the contests that you have joined",
    ]
  }, {
    title: "View Contest Page",
    body: [
      "In the View Contest Page, you will see all the pools which are available to participate in along with information about the contest and prizes. Clicking on one of the pools brings you to the Pool Page",
      "You can ‘Enter the Contest’ which brings up the ‘Enter Contest’ modal. In this modal you can confirm your entry into the contest. If this is a paid contest, your location may be checked (see ‘Geolocation’) and your balance may be checked for paid contests (see ‘Purchases’).",
      "Once successful, you have entered the contest, and will receive a balance of Sparks to make your picks among the available prediction pools. There is also a button to view your picks within that contest, and a button to go to the Leaderboard",
      "For most contests, you must make at least 3 picks across different contestants for a valid entry. As such, you must leave at least 100 sparks per remaining pick.",
    ]
  },
  {
    title: "Sign In Page",
    body: [
      "The sign in page is accessed by clicking ‘Sign In’ from the Home or Pool Pages when you are not signed into the application, or by clicking ‘Sign In’ from the ‘Sign Up’ page",
      "From this page, you can sign in with Google, Facebook by clicking the relevant button, or enter the email and password for your account",
      "If you do not have an account you may click Sign Up to create a new account",
      "You can access the Terms of Service and Privacy policy at the bottom of this page, which you agree to by signing in",
      "If you have forgotten your Password, you can click ‘Forgot Password’ to reset it. This will prompt you for your email address, and you will click submit. This will send you an email with a code, which you can enter along with a new password, and then click ‘Submit’ to reset your password",
    ],
  },
  {
    title: "Sign Up Page",
    body: [
      "The sign up page is accessed by clicking ‘Sign up’ from the Home or Pool Pages when you are not signed into the application, or by clicking ‘Sign up’ from the ‘Sign In’ page",
      "You can access the Terms of Service and Privacy policy at the bottom of this page, which you agree to by signing up",
      "You can create an account using Google, Facebook, or email login. For Google or Facebook you simply click the relevant button",
      "Creating an account with email requires you to enter an email and password. You will then receive a verification email, and must click the link in that email to verify your account.",
    ],
  },
  {
    title: "Profile Page",
    body: [
      "You can reach the Profile Page by selecting your avatar in the top right of the user interface",
      "The profile page shows your avatar, email, and all personal information filled in as part of the ‘Verify Identity’ page",
      "Select the ‘Verify Identity’ button to navigate to the ‘Identity Verification Page’",
      "Select the ‘Sign Out’ button to logout from the application",
      "If your identity is verficiated the system will display a message that your identity is verified and display a Green check mark.",
      "If your identity verification failed you will have a link to ‘Try Again’, or if you have not done identity verification you will have the option to ‘Click here’ to begin it. Either link will bring you to the ‘Identity Verification Page’",
      "You can click the ‘Responsible Gaming’ link to bring you to the ‘Responsible Gaming Page’",
      "Select the 'Edit Avatar' button to open a modal allowing you to customize your profile equip special badges and avatars unlocked through Sparket Pass challenges.",
      "Select the 'Unlock Rewards' button to purchase the Sparket Pass, giving you access to unique rewards.",
      "The 'Sparket Skill Score' utilizes advanced Machine Learning algorithms to accurately reflect your true skill in making picks. Keep making successful picks to watch your Sparket Skill Score rise. Be careful though, as losing picks will lower your score. Use your score as a guide to track your progress and identify opportunities to enhance your picking strategy. The 'Sparket Skill Score' utilizes advanced Machine Learning algorithms to accurately reflect your true skill in making picks. Keep making successful picks to watch your Sparket Skill Score rise. Be careful though, as losing picks will lower your score. Use your score as a guide to track your progress and identify opportunities to enhance your picking strategy. A higher score indicates better skill, with 1 being the highest possible, .5 representing average performance, and 0 indicating room for improvement.",
      "The ‘Deposit Funds’ link will bring you to the ‘Deposit Funds’ modal (See ‘Purchases’)",
      "The ‘My Orders’ link allows you to see your deposits as well as avatar and badge purchase orders and associated statuses",
      "In the notification settings section you may toggle which notifications you would like to receive",
    ],
  },
  {
    title: "Edit Avatar Page",
    body: [
      "The Edit Avatar Page can be accessed by clicking the ‘Edit Avatar’ button on the Profile Page",
      "On this page, you can select a unique avatar and badge to represent your profile",
      "These unique rewards are unlocked by purchasing the 'Sparket Pass' and completing unique challenges."
    ]
  },
  {
    title: "Unlock Rewards",
    body: [
      "Selecting the 'Unlock Rewards' button on the Profile Page will open a new tab allowing you to purchase avatar and badge upgrades for your Profile.",
      "Follow the instructions to gain access to unique and exciting challenges and rewards!"
    ]
  },
  {
    title: "Purchases",
    body: [
      "You may deposit funds into your account by utilizing the Deposit Funds modal. This is accessible via the profile page or will automatically appear if you try to make a purchase or contest entry without enough funds.",
      "The deposit funds modal will display your balance and various deposit methods available to you, including saved methods if applicable",
      "If you have no payment method saved, you will be navigated to a Hosted Payment page to securely enter your payment information",
      "Funds can be utilized to enter contests and purchase various avatars and badges to Upgrade your profile by entering the ‘Unlock Rewards’ section of the Profile Page.",
      "Purchased avatars and badges have no cash or redemption value, nor do they increase the chance of winning any contest or promotion that we run.",
      "If for any reason you are not satisfied with your avatar or badge purchase, you can contact support@betsparket.com to receive credit for a future purchase at any time within 30 days. If there is an issue with your purchase such as a technical glitch or display issue, you may contact us within 30 days for a full refund back to your original form of payment.",
      "Contest entries may be canceled and your entry refunded due to a technical glitch before the event occurs by contacting us at support@betsparket.com. It is your responsibility to ensure the accuracy of your contest entry.",
      "Your USD balance is displayed at the top right of the home page and on the deposit funds modal. If you would like to withdraw your balance please visit the Bankroll page -> toggle to USD to see available options, or you can contact support@betsparket.com"
    ]
  },
  {
    title: "Join Group Page",
    body: [
      "The ‘Join Group Page’ can be accessed by clicking the ‘Join Group’ button on the home page when you are logged in but haven’t previously joined the group",
      "Joining a group gives you access to pick on and participate in the pools, leaderboard, and results confirmation of that group. ",
      "The ‘Join Group Page’ will display relevant terms and conditions of joining that group, including data sharing, contest terms (if applicable), and age acknowledgement",
      "You can click the X on the top right, click outside of the box, or ‘Decline’ the terms to close the page without joining the Group",
      "You can click ‘Accept’ to Join the Group",
    ],
  },
  {
    title: "Pool Page",
    body: [
      "A Pool Page displays relevant details for an individual prediction pool. You can reach this page by clicking the arrow icon ‘>’ (or in the rectangle of a pool) from the Home Page or ‘View Pool’ from the ‘Picks Detail Page’",
      "In the upper section, you can see the pool name, the total pool size, the pool status, the pool close time, the pool win condition, and a popup to display full rules for the pool",
      "In the lower section, you can view each contestant in the pool, including their projected win % (based on initial data when the pool was opened) and current Win odds. For pool types like ‘Win’ where you only need to select one contestant, you can also click the arrow icon ‘>’ (or in the rectangle of a contestant) to open the bottom Confirm Pick modal. For pool types where you need to select multiple contestants, you can utilize the bubbles to the right of each contestant card to select the position that you predict that contestant will fill in. Once all bubbles are filled for each position, the bottom Confirm Pick Modal will appear.",
      "You can share the pool with friends via any external messaging or social media platform by clicking the ‘copy link’ icon in the top right. You can navigate back to the home page via the ‘back home’ button on the top left",
      "If the pool status is In Progress or Settled, you will not be able to make a pick, but you will be able to view the final Odds and Active Picks on each contestant. ",
      "If the pool status is Settled, you will be able to see the winner and the outcome (score)",
      "If you are not logged in, a banner to Sign in Or Sign Up will appear across the screen",
      "If geolocation is required, you will see a status message regarding geolocation until it is completed successfully, at which point the message will disappear. If the geolocation is not successful, you will be able to click an ‘info’ button to open the ‘Geolocation Page’",
      "Pool Types: If available for the pool, there will be a toggle above contestants to select various pool types, such as ‘Win’, ‘Exacta’, ‘Trifecta’, and more. You can learn more about these pick types in the Terminology Section."
    ],
  },
  {
    title: "Confirm Pick Modal",
    body: [
      "When the proper contestant selections are made on the Pool Page, a Confirm Pick Modal will appear at the bottom of the page. This modal will display your available balance, the contestant you selected, and the current odds and projected return. These numbers will update as you Enter an Amount for your pick. Once you enter a valid amount, you can ‘Confirm’ your pick using the button."
    ],
  },
  {
    title: "Pick Confirmed Page",
    body: [
      "The Pick Confirmed Page is reached from clicking ‘Confirm’ on the Confirm Pick Modal",
      "The page allows you to copy a link to share the pool on various social media platforms",
      "You can open the ‘Pick Details’ page to see more details about your pick by clicking the ‘here’ link",
      "You can click outside of the Pick Confirmed page to close it",
    ],
  },
  {
    title: "Pick Details",
    body: [
      "You can view the Pick Details to review details on any picks that you’ve made by clicking the Arrow icon ‘>’(or in the rectangle of a pool) on the My Picks page or by clicking the ‘here’ link on the Pick Confirmed Page",
      "You can view the Group that you made the pick in, the pool, the event start and end time, the contestant you picked, the estimated odds, your user id, your pick id, the time you made the pick, your pick amount, and your Projected or Actual Return",
      "You can select the ‘View Pool’ button to go to the Pool Page",
      "You can click outside of the Pick Review to return to the previous screen",
      "If the Pool is Settled, you will see the amount you won if you picked the correct contestant(s), or the amount you lost if you did not",
    ],
  },
  {
    title: "Bankroll Page",
    body: [
      "The Bankroll Page can be reached by selecting the ‘Bankroll’ menu item on the left panel",
      "From this page you can see your available balance and all transactions impacting your balance, including the transaction name and date",
      "If you have sufficient transactions you can select ‘next page’ at the bottom to see additional transactions",
      "After selecting ‘next page’, you can select ‘previous page’ at the bottom to return to the previous page",
      "You may switch between Sparks and USD transactions (if available) utilizing the toggle at the top right. Within Sparks you may filter for different contest entries if applicable. Switching to USD transactions will display any Withdraw options available to you. Otherwise, you may always reach out to support@betsparket.com for assistance with your financial transactions (see ‘Purchases’ for more information)"
    ],
  },
  {
    title: "My Picks Page",
    body: [
      "The My Picks Page can be accessed via the ‘My Picks’ menu  item on the left panel",
      "From this page you can view each pick you have made, including the contestant and pool name, the pool close date, the amount of your pick, the status of the pool or your pick, and either Projected Return or the actual amount won or lost, as well as filter for a given contest entry (if applicable)",
      "In Progress picks are highlighted in yellow; winning picks in green, losing picks in red; and open picks are not highlighted",
      "You can select the arrow icon ‘>’ or within the rectangle of the pick to navigate to that pick’s information ",
      ""
    ],
  },
  {
    title: "Leaderboard",
    body: [
      "The leaderboard can be accessed via the ‘Leaderboard’ menu item on the left panel",
      "The leaderboard displays your performance against other users within the group that have participated in the same group. You will see a list of the top 100 leaderboard performers and your performance and rank will be highlighted at the top of the list",
      "You can view your overall performance within the group, or if applicable you can filter for various Contests and Phases by utilizing the dropdown filters and buttons at the top of the Leaderboard, or navigate directly to a given contest using the ‘View Contest’ button (if applicable)",
      "The group leaders' equipped 'Sparket Pass' rewards are displayed along with the username. Use this as an oppurtunity to show off your unique rewards and your performance to the rest of the group! In many contests, you can even view another user’s public profile with their avatars/badges and picks by clicking on their username",
      "For optimal display on larger screens, you can hit the ‘Fullscreen’ button to display a fullscreen version of the leaderboard. In this version, a QR code will appear which allows users to scan to access the group",
      "You can also click ‘View Prizes’ to view the latest Contest Terms within the group",
    ],
  },
  {
    title: "Confirm Results Page",
    body: [
      "The Confirm Results Page can be accessed via the ‘Confirm Results’ menu item on the left panel",
      "On this page you can view all pools which are ‘In Progress’ and report the results of those pools. ",
      "You can proceed to the ‘Confirm Results Pool Page’ by clicking on the arrow icon ‘>’ (or within the rectangle of the pool)",
    ],
  },
  {
    title: "Confirm Results Pool Page",
    body: [
      "The Confirm Results Pool page can be accessed by by clicking on the arrow icon ‘>’ or in the rectangle of a pool ‘Confirm Results’ page",
      "On this page, you will see the same information as appears on the ‘Pool Page’ (see above)",
      "However, on this page when clicking on the arrow icon ‘>’ or within the rectangle of a contestant, it will ask you whether you want to ‘Confirm’ the contestant as the winner",
      "Clicking ‘Confirm’ submits your report that this contestant was the winner of the pool. This is separate from any picks",
      "Clicking on another contestant rectangle or arrow icon before clicking ‘Confirm’ will instead ask you whether to confirm the new contestant as the winner",
      "If you have already confirmed a winner for that pool, your confirmed result will appear with an orange check mark and highlight within the Confirm Results Pool Page",
    ],
  },
  {
    title: "Help Page",
    body: [
      "The Help Page can be accessed via the ‘Help’ menu item on the left panel",
      "This is the section that you’re currently reading! It contains useful information about every function that the application uses, terminology about prediction, responsible gaming information, contact details, supported browsers and more! ",
    ],
  },
  {
    title: "Identity Verification Page",
    body: [
      "You can navigate to the ‘Identity Verification Page’ by clicking the ‘Verify Identity’ button on the Profile page or by clicking ‘Try again’ on the Profile Page after a failed or in progress identity verification attempt",
      "On this page, you can verify your identity for verification and compliance purposes. Enter the required information exactly as it appears on your government id and hit ‘Submit’.",
      "Your Profile Page and avatar will indicate the status of your identity verification within the application. A successful verification will result in a green check mark, a pending verification will have an orange ellipses, and a failure will have a red exclamation mark",
    ],
  },
  {
    title: "Responsible Gaming Page",
    body: [
      "The ‘Responsible Gaming Page’ can be reached by clicking on the Responsible Gaming link on the ‘Profile Page’",
      "This page allows you to set limits or exclude yourself from real money prediction for a period of time. You can find more resources on Responsible Gaming on this page.",
      "You can also set limits of either deposits into your account or pick amounts for Daily, Weekly, or Monthly time periods by entering the relevant field and clicking ‘Save’",
    ],
  },
  {
    title: "Geolocation",
    body: [
      "Some contests require geolocation checks in order to participate due to compliance purposes. In most paid contests you can participate in all US states (including Washington D.C.) EXCEPT Arizona, Iowa, Louisiana, Michigan, South Carolina, Tennessee and Washington. Some contests may be different and this will be stated in the Contest Terms.",
    ],
  },
  {
    title: "Miscellaneous",
    body: [
      "Functionality in this web application is the same on desktop and mobile devices. However, if your screen is a certain size, you may have to select the menu icon on the top left (three vertical lines), in order to see the left panel menu items referenced above",
      "Available pools are segmented based on ‘Groups’ within the application. Groups have different themes and can be accessed via unique URL links. Once you are a member of multiple groups, you can switch between them by selecting the down arrow next to group logo at the top center of the application.",
    ],
  },
];


//** when a customization is disabled removes the according sections
export const DISABLED_PAGE_CUSTOMIZATION_PAGE_CONTENT_REMOVALS: { [key in keyof UICustomizations]: SectionRemoval[] } = {
  sign_in_up: [
    { sectionName: "Home Page", row: 5 },
    { sectionName: "Sign In Page" },
    { sectionName: "Sign Up Page" },
    { sectionName: "Join Group Page" }
  ],
  profile_page: [
    { sectionName: "Edit Profile Page" },
    { sectionName: "Profile Page" },
    { sectionName: "Purchases" },
    { sectionName: "Edit Avatar Page" },
    { sectionName: "Upgrade Profile" },
    { sectionName: "Identity Verification Page" },
    { sectionName: "Responsible Gaming Page" },
    { sectionName: "Geolocation" },
    { sectionName: "Unlock Rewards" }
  ],
  balance: [],
  bankroll_page: [
    { sectionName: "Bankroll Page" }
  ],
  settings_page: [],
  terms_of_service: [
    { sectionName: "Home Page", row: 4 }
  ],
  social_sharing_buttons: [
    { sectionName: "Sign Up Page", row: 1 }
  ],
  confirm_results: [
    { sectionName: "Confirm Results Page" },
    { sectionName: "Confirm Results Pool Page" }
  ],
  leaderboard: [
    { sectionName: "Leaderboard" }
  ],
  help_page: [],
  iframe: [],
  black_text: [],
  contact: [],
  contest_group: [
    { sectionName: "Contest Home Page" },
    { sectionName: "View Contest Page" },
    { sectionName: "Geolocation" },
  ]
};
