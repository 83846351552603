import styles from "./contest-image.module.scss";

interface Props {
  url: string | undefined;
}
const ContestImage = ({ url }: Props) => {
  const defaultImage =
    "https://ad-images.s3.us-west-2.amazonaws.com/contest_logos/SPARKET-LOGO-tag-horizontal-head+(1).png";
  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={url || defaultImage} alt="" />
    </div>
  );
};

export default ContestImage;
