import styles from "./styles.module.css";
import { epochToShorterFormattedDateTime } from "utils/formatter-utils/date-formatter.ts";
import BlockyMediumText from "components/custom-texts/blocky-medium-text.tsx";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";

interface Props {
  endAt: number;
}

const TimeDisplay = ({endAt}: Props) => {
  return (
    <BlockyHeavyText className={styles.timeText}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: 10 }}>Closes: &nbsp;</span>
        <BlockyMediumText className={styles.timeText}>
          {epochToShorterFormattedDateTime(endAt)}
        </BlockyMediumText>
      </div>
    </BlockyHeavyText>
  )
}

export default TimeDisplay;