import { Nav, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import OpenContests from "components/contests/open-contests";
import MyContests from "components/contests/my-contests";
import styles from "./styles.module.css";
import ContestNavItem from "./contest-nav-item.tsx";
import JoinGroupButton from "home/join-group-button";
import { useAuth } from "hooks/auth";
import { useAppSelector } from "state/hooks.ts";
import { getIntegratedAppState } from "state/slices/integrated-app-slice.ts";
import { CONTEST_STATE } from "utils/constants.ts";
import useContestData from "hooks/useContestData.ts";
import useQueryParams from "utils/useQueryParams.ts";
import { local as localStorage } from "utils/local-storage";
import { useLocation, useNavigate } from "react-router-dom";

const OPEN_CONTESTS = "Open Contests";
const MY_CONTESTS = "My Contests";
const TABS = [OPEN_CONTESTS, MY_CONTESTS];

const ContestSelector = () => {
  const { contests, joinedContests } = useContestData();
  const navigate = useNavigate();
  const location = useLocation();
  const openContests = contests.filter((c) => c.state === CONTEST_STATE.OPEN);

  const hasOpenContests = openContests.length > 0;
  const hasJoinedContests = joinedContests.length > 0;

  let initialTab = OPEN_CONTESTS;
  if (localStorage.getItem("prevPath") === "/?tab=my_contests") {
    // back button should go back to the correct previous tab
    initialTab = MY_CONTESTS;
  } else if (!hasOpenContests && hasJoinedContests) {
    initialTab = MY_CONTESTS;
  }

  const [selectedTab, setSelectedTab] = useState<typeof TABS[number]>(
    initialTab
  );
  const auth = useAuth();
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  const handleSelect = (key: string | null) => {
    setSelectedTab(key ?? OPEN_CONTESTS);
  };

  const queryParams = useQueryParams();
  const tab = queryParams.get("tab") || OPEN_CONTESTS;

  useEffect(() => {
    if (tab === "my_contests") {
      setSelectedTab(MY_CONTESTS);
      localStorage.setItem("prevPath", location.search);
    } else {
      localStorage.setItem("prevPath", location.search);
    }
  }, [tab]);

  useEffect(() => {
    if (selectedTab === OPEN_CONTESTS) {
      navigate("?tab=open_contests");
    } else {
      navigate("?tab=my_contests");
    }
  }, [selectedTab]);

  return (
    <Tab.Container
      id="contests-tabs"
      activeKey={selectedTab}
      defaultActiveKey={initialTab}
      onSelect={handleSelect}
    >
      <Nav variant="pane" justify className={styles.navBar}>
        {TABS.map((tab) => (
          <ContestNavItem key={tab} text={tab} />
        ))}
      </Nav>

      <div className={styles.joinGroupButton}>
        {auth.signedIn && !isIntegratedApp && <JoinGroupButton />}
      </div>

      <Tab.Content>
        <Tab.Pane eventKey={OPEN_CONTESTS}>
          <OpenContests />
        </Tab.Pane>
        <Tab.Pane eventKey={MY_CONTESTS}>
          <MyContests />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default ContestSelector;
