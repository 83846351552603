import styles from "../styles.module.css";
import { openContestTerms } from "state/slices/legal-modal-slice.ts";
import { GiftFill } from "react-bootstrap-icons";
import { secondaryColor } from "utils/constants.ts";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { useAppDispatch } from "state/hooks.ts";
import { setSelectedContest } from "state/slices/group-slice.ts";
import { Contest } from "interfaces/leaderboard/contest.ts";

interface Props {
  contest: Contest;
}

const PrizeDisplay = ({ contest }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div>
      <div
        onClick={(event) => {
          event.stopPropagation();
          dispatch(setSelectedContest(contest));
          dispatch(openContestTerms());
        }}
        className={styles.prizeContainer}
      >
        <GiftFill size={12} color={secondaryColor} style={{ marginTop: 4, marginBottom: 2 }} />
        <BlockyHeavyText style={{ fontSize: 10, color: 'var(--sparket-off-white)'}}>
          <u><i>Terms</i></u>
        </BlockyHeavyText>
      </div>
    </div>
  );
};

export default PrizeDisplay;