import { Auth } from "aws-amplify";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { useAppSelector } from "state/hooks.ts";
import { getGroupState } from "state/slices/group-slice.ts";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getPrizeoutSettingsPath, getUsersPersonalInformationPath, } from "utils/backend-path-builders.ts";
import { getRequest } from "utils/httpClient.ts";
import { User } from "components/profile/profileInfo.tsx";
import { Mixpanel } from "hooks/mixPanel.tsx";
import PrizeoutSettings from "interfaces/prizeout-settings.ts";

interface Props {
  balance: number | undefined;
  onExit: () => void;
}

const PrizeoutButton = ({ balance, onExit }: Props) => {
  const groupId = useAppSelector(getGroupState).id;
  const [prizeoutSettings, setPrizeoutSettings] = useState<PrizeoutSettings | undefined>();

  useEffect(() => {
    if (!groupId) return;

    getRequest(getPrizeoutSettingsPath(groupId), { skipIntegrationApi: true }).then(
      (data: PrizeoutSettings) => {
        setPrizeoutSettings(data);
      }
    );
  }, [groupId]);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!prizeoutSettings) return;

    getRequest(getUsersPersonalInformationPath()).then((data) => {
      setUser(data);
    });
  }, [prizeoutSettings]);

  const launchPrizeout = async () => {
    if (!user || !prizeoutSettings) {
      return;
    }
    const params = {
      env: process.env.REACT_APP_ENVIRONMENT === "prod" ? "prod" : "sandbox",
      partner_id: prizeoutSettings.partner_id,
      session_id: (await Auth.currentSession()).getIdToken().getJwtToken(),
      callbacks: {
        onInit: () => {
          Mixpanel.track("Prizeout opened", { user });
        },
        onClose: onExit,
      },
    };

    (window as any).prizeoutSDK.init(params);
  };

  if (!prizeoutSettings?.partner_id) return <></>;

  return (
    <Button onClick={launchPrizeout} size="sm">
      <BlockyHeavyText>Withdraw</BlockyHeavyText>
    </Button>
  );
};

export default PrizeoutButton;
